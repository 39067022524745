@import '../../../variables.css';

main .homepage p {
  text-align: justify;
}

main .homepage .ytvideo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

main .homepage .ytvideo iframe {
  width: 95vw;
  max-width: var(--max-width);
  height: var(--size-320);
  border: var(--size-2) solid var(--clr-secondary-5);
  border-radius: var(--radius);
}

main .homepage .box-container {
  margin-top: var(--size-24);
  display: grid;
  justify-items: stretch;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);
  column-gap: var(--gap-2);
  row-gap: var(--gap-3);
}

main .homepage .box-container .box-item {
  padding: var(--size-40) var(--size-26);
  transition: all 0.2s ease-in-out;
  /* box-shadow: var(--box-shadow); */
  box-shadow: 0px 5px 15px -10px var(--clr-black);
  position: relative;
  border-radius: var(--size-10);
}

main .homepage .box-container .box-item .box-item-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}

main .homepage .box-container .box-item .box-item-footer .icon {
  font-size: 5rem;
  color: var(--clr-secondary-1);
  padding: 0 var(--size-18);
}

main .homepage .box-container .box-item:hover,
main .homepage .box-container .box-item:focus {
  transform: scale(1.02);
}

main .homepage .box-container .box-item p {
  padding: 0 0.625rem;
}

/* Media queries */

@media screen and (min-width: 600px) {
  main .homepage .box-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--gap-1);
    row-gap: var(--gap-3);
  }
}

@media screen and (min-width: 768px) {
  main .homepage .ytvideo iframe {
    height: 25rem;
  }

  main .homepage .box-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--gap-2);
    row-gap: var(--gap-3);
  }

  main .homepage .box-container .box-item:hover,
  a main .homepage .box-container .box-item:focus {
    transform: scale(1.07);
  }
}

@media screen and (min-width: 1024px) {
  main .homepage .box-container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--gap-3);
    row-gap: var(--gap-3);
  }
}
