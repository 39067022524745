@import '../../variables.css';

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1rem 1.5rem; */
}

.close-btn {
  font-size: 1.75rem;
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
  color: var(--clr-red-dark);
  margin-top: 0.2rem;
}
.close-btn:hover {
  color: var(--clr-red-light);
}
.logo {
  margin: 0 auto;
  height: 150px;
}

.links a {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: var(--clr-primary-5);
  transition: var(--transition);
  letter-spacing: var(--spacing);
}

.links a:hover,
.links a:focus {
  color: var(--clr-secondary-5);
}
.links a svg {
  font-size: 1.5rem;
  color: var(--clr-secondary-2);
  margin-right: 1rem;
  transition: var(--transition);
  vertical-align: middle;
}
.links a:hover svg {
  color: var(--clr-secondary-5);
}
.social-icons {
  justify-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  /* padding-bottom: 0rem; */
}
.social-icons a {
  font-size: 2rem;
  margin: 0 0.5rem;
  color: var(--clr-secondary-5);
  transition: var(--transition);
  vertical-align: middle;
}
.social-icons a:hover,
.social-icons a:focus {
  color: var(--clr-primary-5);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-black);
  color: var(--clr-primary-5);
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 0.5rem;
  box-shadow: var(--clr-red-dark);
  transition: var(--transition);
  transform: translate(-100%);
  z-index: 1000;
}
.show-sidebar {
  transform: translate(0);
}

.sidebar p {
  font-size: 1.1rem;
  text-align: center;
}

.sidebar p span {
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--clr-primary-5);
  margin-right: var(--size-10);
}

.sidebar p a {
  font-size: 1.8rem;
  padding: 0 var(--size-10);
  color: var(--clr-secondary-2);
  display: inline-flex;
  align-items: center;
}

.sidebar p a:hover,
.sidebar p a:focus {
  color: var(--clr-secondary-5);
}

.sidebar p a:hover > span,
.sidebar p a:focus > span {
  color: var(--clr-secondary-5);
}

.sidebar p span:hover,
.sidebar p span:focus {
  color: var(--clr-secondary-5);
}

@media screen and (min-width: 676px) {
  .sidebar {
    width: 400px;
  }
}
