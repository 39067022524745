.trainings-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.timetable {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.timetable img {
  width: 95%;
  cursor: pointer;
  padding-top: var(--size-35);
}

/* .timetable img:hover,
.timetable img:focus {
  animation: borderImg 1.75s ease-in-out;
  border: var(--size-2) solid rgba(255, 105, 32, 1);
  border-radius: var(--size-3);
} */

/* @keyframes borderImg {
  0% {
    border: var(--size-2) solid rgba(255, 105, 32, 0.2);
  }
  25% {
    border: var(--size-2) solid rgba(255, 105, 32, 0.4);
  }
  50% {
    border: var(--size-2) solid rgba(255, 105, 32, 0.6);
  }
  75% {
    border: var(--size-2) solid rgba(255, 105, 32, 0.8);
  }
  100% {
    border: var(--size-2) solid rgba(255, 105, 32, 1);
  }
} */

.price-list-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-top: var(--size-35);
  background: #ffff;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.columns {
  width: 100%;
  padding: 8px;
  border-radius: var(--size-10);
}

.price {
  list-style-type: none;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 25px;
  text-transform: capitalize;
}

.columns ul {
  border-radius: var(--size-10);
  box-shadow: var(--box-shadow);
}

.price li {
  padding: 20px;
  text-align: center;
}

.price li:nth-of-type(odd) {
  border-top-left-radius: var(--size-10);
  border-top-right-radius: var(--size-10);
  background: var(--clr-secondary-1);
}
.price li:nth-of-type(even) {
  border-bottom-left-radius: var(--size-10);
  border-bottom-right-radius: var(--size-10);
  border-bottom: 2px solid var(--clr-secondary-1);
  border-left: 2px solid var(--clr-secondary-1);
  border-right: 2px solid var(--clr-secondary-1);
}

/* Media queries */
@media screen and (min-height: 1220px) {
  .trainings {
    height: 100vh;
  }
}

@media screen and (min-width: 400px) {
  .price-list-container {
    width: 80%;
  }
}

@media screen and (min-width: 600px) {
  .timetable {
    align-items: center;
  }

  .timetable img {
    margin-top: 0;
  }
  .price-list-container {
    width: 55%;
  }
  .columns {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .price-list-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--gap-1);
    row-gap: var(--gap-1);
  }
  .price-list-container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .price-list-container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--gap-1);
    row-gap: var(--gap-1);
  }
  .price-list-container {
    width: 100%;
    max-width: 1170px;
  }
}
