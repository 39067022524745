@import '../../../variables.css';
.contact {
  padding: unset;
}
.contact .map .btn-container {
  padding: var(--size-70) var(--size-20) var(--size-35) var(--size-20);
}

.contact .map .btn-container .btn {
  background-color: var(--clr-secondary-7);
  border: 1px solid var(--clr-secondary-5);
  width: 70%;
  height: var(--size-70);
  margin-top: var(--size-20);
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
}

.contact .map .btn-container .btn a {
  color: var(--clr-primary-5);
}

.contact .social-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--gap-2);
  padding: var(--size-70) var(--size-20) var(--size-35) var(--size-20);
}

.contact .social-links .link-box {
  margin: 0 auto;
}
.contact .social-links .link-box a {
  font-size: var(--size-50);
  transition: var(--transition);
}

.contact .social-links .link-box:nth-of-type(odd) > a {
  color: var(--clr-secondary-5);
}

.contact .social-links .link-box:nth-of-type(even) > a {
  color: var(--clr-black);
}

.contact .social-links .link-box:nth-of-type(odd) > a:hover,
.contact .social-links .link-box:nth-of-type(odd) > a:focus {
  color: var(--clr-black);
}

.contact .social-links .link-box:nth-of-type(even) > a:hover,
.contact .social-links .link-box:nth-of-type(even) > a:focus {
  color: var(--clr-secondary-5);
}

.contact .open {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--size-35) var(--size-20) var(--size-35) var(--size-20);
}

.contact .open h2 {
  margin: 0;
}

.contact .open h4 {
  margin-top: var(--size-35);
}

.contact .open ul {
  margin-top: var(--size-35);
  width: 90%;
  background-color: var(--clr-secondary-9);
  box-shadow: var(--box-shadow);
  border-radius: var(--size-5);
  padding-top: var(--size-15);
}

.contact .open li {
  margin: var(--size-10) auto;
  display: flex;
  padding: 0 var(--size-20);
}

.contact .open li p {
  width: 50%;
  border-bottom: 1px solid var(--clr-secondary-5);
  color: var(--clr-primary-5);
}

.right {
  text-align: right;
}

.left {
  text-align: left;
  border-right: 1px solid var(--clr-secondary-5);
}

.parking {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.parking p {
  font-size: var(--size-24);
  font-weight: bold;
  margin: 0;
  padding-bottom: var(--size-38);
}

/* Media queries */
@media screen and (min-width: 350px) {
}

@media screen and (min-width: 600px) {
  .contact .social-links {
    grid-template-columns: repeat(3, 1fr);
    row-gap: var(--gap-2);
  }
  .contact .open ul {
    width: 65%;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .contact .open ul {
    width: 50%;
  }
}
