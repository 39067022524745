@import './variables.css';
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Mulish:ital@1&family=Roboto&family=Roboto+Flex:opsz@8..144&display=swap');

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto Flex', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-primary-1);
  line-height: 1.5;
  font-size: var(--size-16);
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: var(--size-12);
}

h1 {
  font-size: var(--size-32);
}
h2 {
  font-size: var(--size-26);
}
h3 {
  font-size: var(--size-24);
}
h4 {
  font-size: var(--size-14);
  line-height: 1.3;
}
p {
  margin-bottom: var(--size-24);
  color: var(--clr-grey-5);
  word-wrap: break-word;
  font-size: var(--size-17);
}
main {
  margin-top: var(--size-96);
  min-width: 100vw;
}

.container {
  min-height: 89vh;
  max-width: var(--max-width);
  margin: 0 auto;
  background-color: var(--clr-primary-5);
  padding: var(--size-38) var(--size-20);
  overflow-anchor: none;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-black);
  color: var(--clr-white);
  padding: var(--size-6) var(--size-12);
  letter-spacing: var(--spacing);
  display: inline-block;
  transition: var(--transition);
  font-size: var(--size-14);
  border: 2px solid var(--clr-black);
  cursor: pointer;
  box-shadow: 0 var(--size-1) var(--size-3) rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  margin: var(--size-8);
}
.btn:hover {
  color: var(--clr-black);
  background: transparent;
}

.underline {
  width: var(--size-112);
  border-top: var(--size-2) solid var(--clr-secondary-5);
  margin-bottom: var(--size-30);
}

.text-link {
  color: var(--clr-secondary-5);
}

.remove {
  display: none;
}

.hide-link {
  display: none;
}

.line {
  border-bottom: 2px solid var(--clr-secondary-5);
}

.add-padding-top {
  padding-top: var(--size-35);
}
.add-padding-bottom {
  padding-bottom: var(--size-35);
}

.page-heading {
  margin: 0 auto;
  border-bottom: 0;
  text-align: center;
  width: 100%;
  font-size: var(--size-30);
}

@media screen and (min-width: 350px) {
  .page-heading {
    font-size: var(--size-32);
  }
}

@media screen and (min-width: 450px) {
  .page-heading {
    width: 77%;
    border-bottom: 1px solid var(--clr-secondary-5);
    font-size: var(--size-34);
  }
}

@media screen and (min-width: 600px) {
  .page-heading {
    width: 60%;
  }
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: var(--size-48);
  }
  h2 {
    font-size: var(--size-36);
  }
  h3 {
    font-size: var(--size-28);
  }
  h4 {
    font-size: var(--size-16);
  }
  body {
    font-size: var(--size-16);
  }
  /* h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  } */
  .partners-box {
    width: 50%;
  }

  .page-heading {
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .page-heading {
    width: 36%;
  }
}
