/*
=============== 
Variables
===============
*/

:root {
  /* lighter shades of primary color*/
  --clr-primary-1: whitesmoke;
  --clr-primary-1: #cccccc;
  --clr-primary-3: #b3b3b3;
  --clr-primary-4: #999999;
  /* primary/main color */
  --clr-primary-5: #ffffff;
  /* darkest shades of primary color */
  --clr-primary-6: #808080;
  --clr-primary-7: #666666;
  --clr-primary-8: #4d4d4d;
  --clr-primary-9: #4d4d4d;
  --clr-primary-10: #333333;
  /* darkest grey - used for headings */
  --clr-secondary-1: #e65f1d;
  --clr-secondary-2: #cc541a;
  --clr-secondary-3: #b34a16;
  --clr-secondary-4: #993f13;
  /* secondary used for borders etc */
  --clr-secondary-5: #ff6920;
  --clr-secondary-6: #ff7836;
  --clr-secondary-7: #ff874d;
  --clr-secondary-8: #ff9663;
  --clr-secondary-9: #ffa579;
  --clr-secondary-10: #ffb490;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem; /*1.6px*/
  --radius: 0.25rem; /*4px*/
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  --gap-0: 0.5rem;
  --gap-1: 1rem;
  --gap-2: 2rem;
  --gap-3: 3rem;
  /* --box-shadow: 16px 19px 22px -14px rgba(34, 34, 2, 0.42); */
  --box-shadow: 0 var(--size-2) var(--size-20) rgba(0, 0, 0, 0.2);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
  --size-1: 0.063rem;
  --size-2: 0.125rem;
  --size-3: 0.188rem;
  --size-4: 0.25rem;
  --size-5: 0.313rem;
  --size-6: 0.375rem;
  --size-7: 0.438rem;
  --size-8: 0.5rem;
  --size-9: 0.563rem;
  --size-10: 0.625rem;
  --size-11: 0.688rem;
  --size-12: 0.75rem;
  --size-13: 0.813rem;
  --size-14: 0.875rem;
  --size-15: 0.938rem;
  --size-16: 1rem;
  --size-17: 1.063rem;
  --size-18: 1.125rem;
  --size-19: 1.188rem;
  --size-20: 1.25rem;
  --size-21: 1.313rem;
  --size-22: 1.375rem;
  --size-23: 1.438rem;
  --size-24: 1.5rem;
  --size-25: 1.563rem;
  --size-26: 1.625rem;
  --size-27: 1.688rem;
  --size-28: 1.75rem;
  --size-29: 1.813rem;
  --size-30: 1.875rem;
  --size-31: 1.938rem;
  --size-32: 2rem;
  --size-33: 2.063rem;
  --size-34: 2.125rem;
  --size-35: 2.188rem;
  --size-36: 2.25rem;
  --size-37: 2.313rem;
  --size-38: 2.375rem;
  --size-39: 2.438rem;
  --size-40: 2.5rem;
  --size-45: 2.813rem;
  --size-48: 3rem;
  --size-50: 3.125rem;
  --size-60: 3.75rem;
  --size-70: 4.375rem;
  --size-96: 6rem;
  --size-112: 7rem;
  --size-130: 8.125rem;
  --size-200: 12.5rem;
  --size-250: 15.625rem;
  --size-280: 17.5rem;
  --size-300: 18.75rem;
  --size-305: 19.063rem;

  --size-320: 20rem;
  --size-325: 20.313rem;
  --size-330: 20.625rem;

  --size-400: 25rem;
}
