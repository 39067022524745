@import '../../variables.css';

.grid-gallery-container {
  margin-top: 2rem;
}

.grid-gallery-container h1 {
  margin: 4rem auto 3.5rem auto;
}

.grid-gallery-container .grid-gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 3px;
  position: relative;
}

.grid-gallery-container .grid-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.4s ease;
}

.grid-gallery-container .grid-gallery img:hover {
  transform: scale(0.98);
  border-radius: 8px;
}

/* .grid-gallery::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--clr-black);
  opacity: 0.2;
  top: 0;
  right: 0;
} */

.grid-gallery-container .grid-gallery .item1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  border-top-left-radius: 8px;
}

.grid-gallery-container .grid-gallery .item2 {
  grid-column: 3 / 5;
  grid-row: 1 / 1;
}

.grid-gallery-container .grid-gallery .item3 {
  grid-column: 5 / 5;
  grid-row: 1 / 1;
  border-top-right-radius: 8px;
}

.grid-gallery-container .grid-gallery .item4 {
  grid-column: 3 / 4;
  grid-row: 2 / 2;
}
.grid-gallery-container .grid-gallery .item5 {
  grid-column: 4 / 6;
  grid-row: 3 / 3;
}

.grid-gallery-container .grid-gallery .item6 {
  grid-column: 4 / 6;
  grid-row: 2 / 2;
}

.grid-gallery-container .grid-gallery .item7 {
  grid-column: 1 / 3;
  grid-row: 3 / 3;
}

.grid-gallery-container .grid-gallery .item8 {
  grid-column: 3 / 4;
  grid-row: 3 / 3;
}

.grid-gallery-container .grid-gallery .item9 {
  grid-column: 1 / 6;
  grid-row: 4 / 6;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
